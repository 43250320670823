<script lang="ts" setup>

</script>

<template>
  <svg width="52" height="54" viewBox="0 0 52 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="var(--esc-vector-opacity, 0.5)">
      <path
        d="M2.71454 26.175L2.71022 26.2326L2.70925 26.2904C2.60547 32.4339 2.88535 37.4353 3.76126 41.2933C4.63442 45.1391 6.17271 48.1657 8.84864 49.9153C11.5325 51.6701 14.8978 51.8389 18.7182 51.046C22.5446 50.2518 27.1437 48.4292 32.5998 45.7793L32.655 45.7525L32.7084 45.7224C33.7459 45.1374 34.7658 44.5586 35.7187 44.0063C41.1066 41.2871 44.8343 38.4722 47.0533 35.5012C49.3404 32.4392 49.9959 29.2378 49.2453 26.0554C48.5217 22.9875 46.5433 20.1541 44.0148 17.5713C41.4684 14.9702 38.2182 12.4768 34.7066 10.1075L34.6601 10.0761L34.6119 10.0474C29.3309 6.90027 24.8533 4.65214 21.0711 3.48776C17.3006 2.327 13.9111 2.14849 11.0579 3.58729C8.1954 5.03074 6.36276 7.8535 5.13166 11.551C3.89886 15.2536 3.16622 20.1374 2.71454 26.175Z"
        fill="var(--esc-vector-fill-color, #EAB732)"
        stroke="var(--esc-vector-stroke-color, #F8F7F4)"
        stroke-width="var(--esc-vector-stroke-width, 4)"
      />
    </g>
  </svg>
</template>

<style scoped>
svg {
  mix-blend-mode: var(--esc-vector-mix-blend-mode, soft-light);
}
</style>
